import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from './Button'
import './Footer.css'

function Footer() {
  return (
    <div className='footer-container'>
        <section className="footer-subscription">
            <p className="footer-subscriptions-heading">
                Join
            </p>
        <p className="footer-subscription text">More text</p>
        <div className="input-areas">
            <form action="">
                <input type="email" name="email" placeholder='Your email' className="footer-input" />
                <Button>Hi</Button>
            </form>
        </div>
        </section>
        <div className="footer-links">
            <div className="footer-link-wrapper">
                <div className="footer-link-items">
                    <h2>About Us</h2>
                    <Link to='/'>How it works</Link> 
                    <Link to='/'>Customer Reviews</Link> 
                </div>
                <div className="footer-link-items">
                    <h2>Location</h2>
                    <Link to='/'>How it works</Link> 
                    <Link to='/'>Customer Reviews</Link> 
                </div>
                <div className="footer-link-items">
                    <h2>Contact Us</h2>
                    <Link to='/'>How it works</Link> 
                    <Link to='/'>Customer Reviews</Link> 
                </div>
            </div>
        </div>
        <section className='social-media'>
            <div className="social-media-wrap">
                <div className="footer-logo">
                    <Link className="social-logo">
                        AFI
                    </Link>
                </div>
                <small className='website-rights'>AFI 2024</small>
                <div className="social-icons">
                    <Link className="social-icon-link facebook" to='/' target='_blank' rel="noopener noreferrer" aria-label="Facebook">Facebook</Link>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Footer