import React from "react";
import Cards from "../../Cards";
import HeroSection from "../../HeroSection";

function Savory() {
  const homeCardsData = [
    {
      src: "images/snacks.jpg",
      text: "Snacks",
      path: "/flavors",
      label: "flavor",
    },{
      src: "images/flavors.png",
      text: "Processed Meat & Meat Alternatives",
      path: "/flavors",
      label: "flavor",
    },{
      src: "images/flavors.png",
      text: "Soups, Broths, & Bouillions",
      path: "/flavors",
      label: "flavor",
    },{
      src: "images/flavors.png",
      text: "Sauces and Condiments",
      path: "/flavors",
      label: "flavor",
    },{
      src: "images/flavors.png",
      text: "Dairy Products",
      path: "/flavors",
      label: "flavor",
    },{
      src: "images/flavors.png",
      text: "Seasoning Blends and Rubs",
      path: "/flavors",
      label: "flavor",
    },]
  return (
    <>
      <HeroSection
        heading="Savory flavoring are found in wide application range. Anywhere you need depth, richness, and umami qualities, you might need something savory."
        // subheading="Find out how AFI can help"
        backgroundImage=".././images/flavors1"
        buttonText="Learn More"
        buttonLink="/about"
        showButton={false}
        buttonStyle="btn--primary" // Use primary style
        buttonSize="btn--large" // Use large button
      />
      <Cards cardsTitle = 'Use Cases' cardsData={homeCardsData} cardClassName="flavor-card"
        displayStyle="flex"/> 
    </>
  );
}

export default Savory;
