import React from "react";
import CardItem from "./CardItem";
import "./Cards.css";
import "../App.css";

function Cards({ cardsTitle, cardsData, cardClassName, displayStyle }) {
  return (
    <div className="cards">
      <h1>{cardsTitle}</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items" style={{ display: displayStyle }}>
            {cardsData.map((card, index) => (
              <CardItem
                key={index}
                src={card.src}
                text={card.text}
                path={card.path}
                label={card.label}
                className={cardClassName}
              />
            ))}{" "}
          </ul>
          {/* We can add new rows of cards here if needed */}
          {/* <ul className="cards__items">
            <CardItem src="/" text="Flavors" path="/flavors" label="flavor" />
            <CardItem src="/" text="Flavors" path="/flavors" label="flavor" />
          </ul> */}
        </div>
      </div>
    </div>
  );
}

export default Cards;
