import React from "react";
import ContactForm from "../ContactForm";
import Footer from "../Footer";
import Map from "../Map";

function Contact() {
  return (
    <>
      <div>Contact</div>
      <ContactForm />
      <Map  />
      <Footer />
    </>
  );
}

export default Contact;
