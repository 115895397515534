import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";
import { Link } from "react-router-dom";

function HeroSection({
  heading,
  subheading,
  backgroundImage,
  buttonText,
  buttonLink,
  bgClassName,
  showButton,
  buttonStyle,
  buttonSize,
}) {
  return (
    <>
      <div
        className={`hero-container ${bgClassName}`}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <video src={backgroundImage} autoPlay loop muted></video>
        <div className="text-wrap">
          <h1>{heading}</h1>
          <p>{subheading}</p>
        </div>
        <div className="hero-btns">
          {/* <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
          >
            Request a Sample
          </Button> */}
          {showButton && (
            <Link to={buttonLink}>
              <Button buttonStyle={buttonStyle} buttonSize={buttonSize}>
                {buttonText}
              </Button>
            </Link>
          )}
          {/* <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
          >
            Contact Us
          </Button> */}
        </div>
      </div>
    </>
  );
}

export default HeroSection;
