import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

// Map container
const containerStyle = {
  width: "100%",
  height: "400px",
};

// Where the map will be centered
const center = {
  lat: 43.75507,
  lng: -79.268,
};

function Map() {
  return (
    <>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12} // Adjust the zoom level as needed
        >
          {/* Add a marker */}
          <Marker position={center} />
        </GoogleMap>
      </LoadScript>
    </>
  );
}

export default React.memo(Map);
