import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/pages/Home"; // Import Home component
import Flavors from "./components/pages/Flavors";
import Contact from "./components/pages/Contact";
import Savory from "./components/pages/flavor_pages/Savory";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/flavors" element={<Flavors />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/savory" element={<Savory />} />
      </Routes>
    </Router>
  );
}

export default App;
