import React from "react";
import "../../App.css";
import Cards from "../Cards";
import Footer from "../Footer";
import HeroSection from "../HeroSection";

function Home() {
  const homeCardsData = [
    {
      src: "images/flavors.png",
      text: "New Flavor Ideas",
      path: "/flavors",
      label: "flavor",
    },
    {
      src: "images/spices.png",
      text: "Fragrances from Travel",
      path: "/fragrances",
      label: "fragrance",
    },
  ];
  return (
    <div>
      <HeroSection
        heading="Where Expertise meets Senses"
        subheading="Find out how AFI can help"
        backgroundImage=".././videos/video-1.mp4"
        buttonText="Learn More"
        buttonLink="/about"
        showButton={true}
        buttonStyle="btn--primary" // Use primary style
        buttonSize="btn--large" // Use large button
      />
      <div className="cert-img-wrap">
        <img src="./images/certs.png" alt="" />
      </div>

      <Cards cardsTitle='Blog Posts' cardsData={homeCardsData} />
      <Footer />
    </div>
  );
}

export default Home;
