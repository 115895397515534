import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  const handleClick = () => {
    setClick(!click);
  };

  const closeMobileMenu = () => {
    setClick(false);
  };

  const showButton = () => {
    // a mobile window is 960 wide
    if (window.innerWidth <= 960) {
      setIsMobile(true);
      console.log("true");
    } else {
      setIsMobile(false);
      console.log("false");
    }
  };
  useEffect(() => {
    // Initial button visibility based on window width
    showButton();
    // Add event listener for window resize
    window.addEventListener("resize", showButton);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", showButton);
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            Aromatics + Flavors
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/flavors"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Flavors
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/fragrances"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Fragrances
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Contact
              </Link>
            </li>
            {isMobile && (
              <li className="nav-item">
                <Button className="navbar-button" buttonStyle="btn--outline">
                  Sign Up
                </Button>
              </li>
            )}
          </ul>
          {!isMobile && (
            <Button className="nav-links-mobile" buttonStyle="btn--outline">
              Sign Up
            </Button>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
