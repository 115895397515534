import React from "react";
import "../../App.css";
import HeroSection from "../HeroSection";
import Cards from "../Cards";
import Footer from "../Footer";

function Flavors() {
  const flavorsCardsData = [
    {
      src: "images/herbs.jpeg",
      text: "Baking Flavors",
      // path: "/flavors",
      label: "baking flavors",
    },
    {
      src: "images/herbs2.jpeg",
      text: "Savory Flavors",
      path: "/savory",
      label: "savory flavors",
    },
    {
      src: "images/herbs3.jpeg",
      text: "Pharmaceutical Flavors",
      // path: "/fragrances",
      label: "savory flavors",
    },
    {
      src: "images/herbs4.jpeg",
      text: "Fruit Flavors",
      // path: "/fragrances",
      label: "savory flavors",
    },
    {
      src: "images/fruits.jpeg",
      text: "Dairy Flavors",
      // path: "/flavors",
      label: "baking flavors",
    },
    {
      src: "images/herbs2.jpeg",
      text: "Inhalent Flavors",
      // path: "/fragrances",
      label: "savory flavors",
    },
    {
      src: "images/herbs3.jpeg",
      text: "Confection Flavors",
      // path: "/fragrances",
      label: "savory flavors",
    },
    {
      src: "images/herbs4.jpeg",
      text: "Sports Flavors",
      // path: "/fragrances",
      label: "savory flavors",
    },
  ];
  return (
    <>
      <HeroSection
        heading="Our Flavors"
        subheading=""
        backgroundImage=".././images/spices2.jpeg"
        bgClassName="flavor_bg_image"
        buttonText="Learn More"
        buttonLink="/about"
      />
      <Cards
        cardsTitle="Flavor Offerings"
        cardsData={flavorsCardsData}
        cardClassName="flavor-card"
        displayStyle="flex"
      />
      <Footer />
    </>
  );
}

export default Flavors;
